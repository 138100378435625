import React, { useMemo } from 'react';
import { Link } from 'gatsby';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

export default function() {
  const specsElements = useMemo(() => specs.map(specElement), specs);
  const title = 'Todas nuestras especialidades';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <section>
        <div className="grid grid--wrap">{specsElements}</div>
      </section>
    </Layout>
  );
}

function specElement({ src, title, text, link }, index) {
  return (
    <div key={index} style={{ flexBasis: 300 }}>
      <div className="img">
        <img src={src} alt={title} />
      </div>
      <h3>{title}</h3>
      <p>{text}</p>
      <Link to={link}>Leer más</Link>
    </div>
  );
}

const img = 'https://via.placeholder.com/400x300';
const specs = [
  {
    src: img,
    title: 'Implantes dentales',
    text:
      'Un implante dental es un elemento biocompatible. Está fabricado con titanio y nuestro cuerpo lo acepta sin provocar una reacción adversa. Su función es articular como raíz artificial para servir de sustento estable para la colocación segura, consistente y firme de un elemento protético que suplirá el o los dientes perdidos.',
    link: '/implantes-dentales/',
  },
  {
    src: img,
    title: 'Ortodoncia y Ortopedia',
    text:
      'La alineación de los dientes y la corrección de los problemas de mordida toma fundamental importancia en nuestra vida cotidiana ya que afecta no sólo nuestra estética sino también funciones vitales como respiración, deglución, fonación y la posición postural. La ortopedia y la ortodoncia pueden ser una alternativa ideal para recuperar el equilibrio y la armonía de las piezas dentarias, la cavidad bucal y las funciones afectadas.',
    link: '/ortodoncia-y-ortopedia/',
  },
  {
    src: img,
    title: 'Blanqueamiento dental',
    text:
      'Múltiples son las causas que originan la pérdida de varios puntos en la tonalidad y coloración de las piezas dentales. Entre otras se encuentran la ingesta de: café, mate, te, bebidas colas en exceso, tabaco, sustancias químicas utilizadas en colutorios o antisépticos, medicamentos, la oxidación de antiguas amalgamas y tratamientos de conducto. El blanqueamiento dental es un tratamiento que tiene como finalidad devolverle a los dientes su tono original a través de un proceso de remoción de las sustancias depositadas sobre el esmalte dentario.',
    link: '/blanqueamiento-dental/',
  },
  {
    src: img,
    title: 'Prótesis dentales',
    text:
      'Habitualmente se presentan en la clínica pacientes que han perdido gran parte de su dentadura ocasionando como consecuencia de ello problemas funcionales y estéticos. La mayor preocupación del paciente parcial o totalmente desdentado que recibirá una prótesis es el hecho que dicha estructura se adapte a su cavidad bucal y no resulte molesta. Es por ello que es uno de nuestros pilares fundamentales un buen diagnóstico y una exhaustiva planificación del tratamiento y prótesis a utilizar usando los mejores materiales y de esta manera satisfacer a nuestros pacientes estética y funcionalmente.',
    link: '/protesis-dentales/',
  },
  {
    src: img,
    title: 'Odontopediatría',
    text:
      'Odontopediatra es la especialidad que se encarga de diagnosticar, atender y resolver los problemas odontológicos en niños y adolescentes. El odontopediatra será, por tanto, el encargado de explorar y tratar al paciente niño o adolescente. Es muy importante destacar en edades tempranas el rol del Odontopediatra como agente de prevención. Esto se logra gracias a la aplicación de fluoruros, un buen asesoramiento dietario y la enseñanza de una correcta técnica de cepillado de acuerdo a la edad del paciente. La motivación y la enseñanza de buenos hábitos son el factor predisponente para lograr una boca en salud.',
    link: '/odontopediatria/',
  },
  {
    src: img,
    title: 'Periodoncia',
    text:
      'La enfermedad periodontal se constituye por un grupo de procesos que afectan los tejidos que soportan y rodean los dientes. Las dos enfermedades periodontales más comunes son la gingivitis y la periodontitis. La gingivitis es una enfermedad reversible que produce una inflamación en los tejidos que rodean los dientes y suele manifestarse con sangrados posteriores al cepillado. La periodontitis se manifiesta con una inflamatoria progresiva que destruye las fibras de los ligamentos periodontales y el hueso alveolar que sostienen al diente, y puede eventualmente ocasionar la pérdida del mismo.',
    link: '/periodoncia/',
  },
  {
    src: img,
    title: 'Endodoncia',
    text:
      'La endodoncia o tratamiento de conducto es el procedimiento que se realiza para eliminar el dolor y la infección de la pulpa dental, constituida por el nervio que le da la sensibilidad al diente y vasos sanguíneos, que están dentro del diente y recorre la raíz. El tratamiento de conducto consiste en perforar el diente e introducir unas limas, con las que, mediante una tarea de frotación en las paredes de los conductos se realiza una limpieza eliminando la infección. En paralelo se hacen lavajes de limpieza de la zona. Luego se sellan los conductos evitando su reinfección. De esta manera se conserva la pieza dentaria y puede ser restaurada a corto plazo con el fin de recuperar su función y apariencia.',
    link: '/endodoncia/',
  },
  {
    src: img,
    title: 'Rellenos oseos',
    text:
      'Cuando los huesos maxilares han perdido volumen debido a la reabsorción sufrida, el cirujano máxilo-facial con el fin de recuperar el continente perdido puede realizar injertos o rellenos óseos. Generalmente este procedimiento es utilizado previo a la colocación de implantes, o en traumatología o periodoncia, donde muchas veces el defecto óseo dificulta la estabilidad de las piezas y/o la estética. En estos casos los rellenos son utilizados para completar estos espacios favoreciendo la funcionalidad o como paso previo indispensable a algún tratamiento.',
    link: '/rellenos-oseos/',
  },
  {
    src: img,
    title: 'Cirugía oral y maxilofacial',
    text:
      'La cirugía oral y maxilofacial es la especialidad médica odontológica (estomatológica) encargada del diagnóstico, la prevención y el tratamiento de las patologías bucales, faciales y cervicales. Por su diversidad esta especialidad y la consiguiente acción del cirujano abarcan numerosos procedimientos, incluyendo esto desde extracciones, hasta cirugías estéticas, oncológicas y reconstructivas.',
    link: '/cirugia-oral-y-maxilofacial/',
  },
];
